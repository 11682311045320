import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function sliderImages2() {
	if (window.matchMedia('(max-width:767px)').matches) {
		return;
	}

	Swiper.use([Autoplay, Navigation, Pagination]);

	new Swiper('.js-slider-swiper-images-2', {
		spaceBetween: 0,
		centeredSlides: true,
		speed: 6000,
		autoplay: {
			enabled: true,
			delay: 1,
		},
		loop: true,
		slidesPerView: 1,
		allowTouchMove: false,
		disableOnInteraction: true,
		breakpoints: {
			575: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			991: {
				slidesPerView: 4,
			},
			1250: {
				slidesPerView: 6,
			},
		},
	});
}

export default sliderImages2;
