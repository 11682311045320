/*
 * Slide out
 *
 * runs the functionality of functions inside the slideout.
 * The actual opening and closing of the slide-out is handled in hamburger.js
 */

let menuItem = '.js-menu-item';
let activeMenuClass = 'm-slide-out__nav-menu--active';

export function slideOut() {
	// add for active on hover
	$(menuItem).hover(
		function() {
			$(menuItem).removeClass(activeMenuClass);
			$(this).addClass(activeMenuClass);
		},
		function() {
			$(menuItem).removeClass(activeMenuClass);
		},
	);
}
