import Swiper, { Autoplay, Navigation } from 'swiper';

function sliderImages() {
	Swiper.use([Autoplay, Navigation]);

	new Swiper('.js-slider-swiper-images', {
		loop: true,
		grabCursor: true,
		centeredSlides: true,
		speed: 600,
		autoplay: false,
		slidesPerView: 1,
		spaceBetween: 10,
		loopAdditionalSlides: 2,
		navigation: {
			prevEl: '.js-slider-swiper-images-btn-prev',
			nextEl: '.js-slider-swiper-images-btn-next',
		},
		pagination: false,
		breakpoints: {
			544: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
		},
	});
}

export default sliderImages;
