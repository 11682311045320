let debugControlsTrigger = '.js-debug-controls-trigger';
let debugControlsPanel = '.js-debug-controls-panel';

let debugControlsTextAlignmentLeft = '.js-debug-left';
let debugControlsTextAlignmentCenter = '.js-debug-center';
let debugControlsTextAlignmentDefault = '.js-debug-default';
let debugControlsLightTextBlackBG = '.js-debug-light-text-black-bg';
let debugControlsDarkTextWhiteBG = '.js-debug-dark-text-white-bg';
let debugControlsDefaultTextDefaultBG = '.js-debug-default-text-default-bg';
let debugControlsShowBlockName = '.js-debug-show-block-name';

let block = $('.o-block');
// let defaultBlockBG = $('.o-block__bg');
let hero = $('.o-hero');

export default () => {
	// Toggle CSS class on an element on click
	$(debugControlsTrigger).on('click', function(e) {
		e.preventDefault();
		$(debugControlsPanel).toggleClass('m-debug-controls__panel--active');
	});

	$(debugControlsTextAlignmentLeft).on('click', function(e) {
		e.preventDefault();
		block.removeClass('o-block--align-default o-block--align-center');
		block.addClass('o-block--align-left');

		hero.removeClass('o-hero--align-default o-hero--align-center');
		hero.addClass('o-hero--align-left');
	});

	$(debugControlsTextAlignmentCenter).on('click', function(e) {
		e.preventDefault();
		block.removeClass('o-block--align-left o-block--align-default');
		block.addClass('o-block--align-center');

		hero.removeClass('o-hero--align-left o-hero--align-default');
		hero.addClass('o-hero--align-center');
	});

	$(debugControlsTextAlignmentDefault).on('click', function(e) {
		e.preventDefault();
		block.removeClass('o-block--align-left o-block--align-center');
		block.addClass('o-block--align-default');

		hero.removeClass('o-hero--align-left o-hero--align-center');
		hero.addClass('o-hero--align-default');
	});

	$(debugControlsLightTextBlackBG).on('click', function(e) {
		e.preventDefault();
		$('.o-block__bg').removeAttr('style');
		$('.o-block__bg').css('background-color', '#000');
		block.removeClass('o-block--color-default o-block--color-dark');
		block.addClass('o-block--color-light');

		hero.removeClass('o-hero--color-default o-hero--color-dark');
		hero.addClass('o-hero--color-light');
	});

	$(debugControlsDarkTextWhiteBG).on('click', function(e) {
		e.preventDefault();
		$('.o-block__bg').removeAttr('style');
		$('.o-block__bg').css('background-color', '#fff');
		block.removeClass('o-block--color-default o-block--color-light');
		block.addClass('o-block--color-dark');

		hero.removeClass('o-hero--color-default o-hero--color-light');
		hero.addClass('o-hero--color-dark');
	});

	$(debugControlsDefaultTextDefaultBG).on('click', function(e) {
		e.preventDefault();
		$('.o-block__bg').removeAttr('style');
		block.removeClass('o-block--color-default o-block--color-light');
		block.addClass('o-block--color-default');

		hero.removeClass('o-hero--color-dark o-hero--color-light');
		hero.addClass('o-hero--color-default');
	});

	$(debugControlsShowBlockName).on('click', function(e) {
		e.preventDefault();

		// DRY block name/version
		if ($('.debug-block-name').length) {
			return;
		}

		// Loop through all Blocks
		block.map((index, block) => {
			const blockElm = $(block);

			const classes = blockElm.attr('class').split('\n');
			// Try to find component_class
			let blockName = '';
			let versionIndex = 0;

			classes.find((className, index) => {
				className = className.replaceAll('\t', '');
				if (
					className === '' ||
					className.includes('o-block') ||
					className.includes('--')
				) {
					return false;
				}

				blockName = className;
				versionIndex = index + 1;
				return true;
			});

			// Assuming that component_class next index is version
			const versionName = versionIndex
				? classes[versionIndex].split('--')[1].replaceAll('-', ' ')
				: '';
			blockName = blockName
				.split('-')
				.reduce(
					(fullName, namePart, index) =>
						index !== 0
							? fullName +
							  ` ${namePart.charAt(0).toUpperCase() +
									namePart.slice(1)}`
							: fullName,
					'',
				);

			blockElm.before(
				`<div class="debug-block-name" style="position: absolute; right: 0; background: rgba(0, 0, 0, 0.8); padding: 10px; font-size: 16px; z-index: 10; color: #fff;">
					<span style="display: block;">Block Name: ${blockName}</span>
					<span style="display: block;">Block Version: ${versionName}</span>
				<div>`,
			);
		});
	});
};
