import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function latestPostsB1() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	$('[data-module="latestPostsB1"]').each(function() {
		new Swiper($(this)[0], {
			loop: false,
			slidesPerView: 'auto',
		});
	});
}

export default latestPostsB1;
