import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function latestPosts1() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	$('[data-module="latestPosts1"]').each(function() {
		new Swiper($(this)[0], {
			loop: false,
			slidesPerView: 'auto',
		});
	});
}

export default latestPosts1;
