class FilterTypeOne {
	constructor(block) {
		this.container = block;

		this._initProps();
		this._initElements();
		this._initStates();
		this._initListeners();
	}

	/**
	 * All needed parameters of the block are set up here
	 *
	 * @private
	 */
	_initProps() {
		// Main organism's class
		this.componentClass = 'o-filter-1';

		// Block Top offset
		this.blockOffsetTop = 200;

		// Data from backend
		// URL for ajax requests
		// eslint-disable-next-line no-undef
		this.ajaxUrl = filterTypeOne.url;
		// Name of the action
		// eslint-disable-next-line no-undef
		this.ajaxAction = filterTypeOne.action;
		// Nonce code for ajax requests
		// eslint-disable-next-line no-undef
		this.ajaxNonce = filterTypeOne.nonce;

		// Current data of the filters form
		this.formData = {};

		// Are we currently waiting for AJAX response?
		this.isProcessingAjax = false;

		// Responsive
		// Filter block animation duration
		this.filtersBlockAppearingDuration = 500;
		this.filtersBlockDefaultClasses = [
			'xs:opacity-100',
			'fixed',
			'xs:static',
			'py-16',
			'xs:py-0',
			'top-0',
			'left-0',
			'xs:inset-auto',
			'w-full',
			'h-full',
			'xs:h-auto',
			'transition-opacity',
			`duration-${this.filtersBlockAppearingDuration}`,
			'bg-brand-color-17',
			'xs:bg-transparent',
			'z-20',
			'xs:z-[1]',
			'xs:pointer-events-auto',
		];
		this.filtersBlockOpenedClasses = this.filtersBlockDefaultClasses.concat(
			['opacity-100', 'pointer-events-auto'],
		);
		this.filtersBlockClosedClasses = this.filtersBlockDefaultClasses.concat(
			['opacity-0', 'pointer-events-none'],
		);
		// Edge resolutions
		this.maxMobileResolution = 543;
		// Current window resolution
		this.currentResolution = window.screen.width;
		// Is Mobile mode
		this.isMobileMode = this.currentResolution <= this.maxMobileResolution;
	}

	/**
	 * All DOM elements within the main Block which we are going to use are set up here
	 *
	 * @private
	 */
	_initElements() {
		// Form
		this.filtersForm = this.container.querySelector(
			`[data-role='filters-form']`,
		);
		// Post Type pills
		this.postTypesPills = this.container.querySelectorAll(
			`[data-role='post-types-pills']`,
		);
		// The main container of posts
		this.filterList = this.container.querySelector(
			`[data-role='posts-list']`,
		);
		// Page Input
		this.pageInput = this.container.querySelector(`[name='page']`);
		// Navigation Container
		this.navigationBlocks = this.container.querySelectorAll(
			`[data-role='navigation-block']`,
		);
		// Filters Block header
		this.filtersHeader = this.container.querySelector(
			`[data-role='filters-header']`,
		);
		// Filters Block
		this.filtersBlock = this.container.querySelector(
			`[data-role='filters-block']`,
		);
		// Filters Toggler Button
		this.filtersToggler = this.container.querySelector(
			`[data-role='toggle-filters']`,
		);
		// Filters Close Button
		this.filtersCloseButtons = this.container.querySelectorAll(
			`[data-role='close-filters-button']`,
		);
	}

	/**
	 * Set certain states for elements on class init
	 *
	 * @private
	 */
	_initStates() {
		// Update the initial form data
		this._updateFormData();
	}

	/**
	 * All events listeners are listed here
	 *
	 * @private
	 */
	_initListeners() {
		// Post Types Pills Functionality
		if (this.postTypesPills.length) {
			[...this.postTypesPills].map(pillsContainer => {
				const inputs = pillsContainer.querySelectorAll(
					'input[type="radio"]',
				);
				if (!inputs.length) {
					return false;
				}
				inputs.forEach(input => {
					input.addEventListener(
						'change',
						this._postTypesPillsHandler.bind(this),
					);
				});
			});
		}
		// Navigation
		if (this.navigationBlocks) {
			[...this.navigationBlocks].map(block => {
				block.addEventListener(
					'click',
					this._navigationHandler.bind(this),
				);
			});
		}
		if (this.filtersToggler) {
			this.filtersToggler.addEventListener(
				'click',
				this._openFiltersBlock.bind(this),
			);
		}
		if (this.filtersCloseButtons.length) {
			[...this.filtersCloseButtons].map(button => {
				//console.log(button);
				button.addEventListener(
					'click',
					this._closeFiltersBlock.bind(this),
				);
			});
		}
		// Updating Working mode on resize
		window.addEventListener('resize', this._updateMode.bind(this), true);
	}

	/**
	 * Post Types Pills handler
	 *
	 * @param event
	 * @private
	 */
	_postTypesPillsHandler(event) {
		// Do nothing when AJAX is processing
		if (this.isProcessingAjax) {
			event.preventDefault();
			return;
		}

		// Set the first page for the query
		this.pageInput.value = 1;
		// We need to close filters block is it's mobile resolutions and wait until the animation is finished and only then show loader
		let timeout = 0;
		if (this.isMobileMode) {
			this._closeFiltersBlock();
			timeout = this.filtersBlockAppearingDuration;
		}
		setTimeout(() => {
			// Send query
			this._requestPosts();
		}, timeout);
	}

	/**
	 * Collect the current form data and store them in the Class's parameter
	 *
	 * @private
	 */
	_updateFormData() {
		let formData = new FormData(this.filtersForm);
		formData.append('action', this.ajaxAction);
		formData.append('_nonce', this.ajaxNonce);

		// For dealing with multiselect values we need to filter it
		const data = {};
		formData.forEach(function(value, key) {
			const prev = data[key];
			if (prev) {
				data[key] = `${prev}, ${value}`;
			} else {
				data[key] = value;
			}
		});

		this.formData = data;
	}

	/**
	 * Sending AJAX request with parameters for getting posts list output
	 *
	 * @private
	 */
	_requestPosts() {
		// Update current form data before sending it to backend
		this._updateFormData();

		// eslint-disable-next-line jquery/no-ajax
		$.ajax({
			type: 'POST',
			url: this.ajaxUrl,
			data: this.formData,
			beforeSend: () => {
				this.isProcessingAjax = true;
				// Show loader
				this._updateLoaderState();
			},
			success: response => {
				// New output from backend (posts list)
				let postsListOutput = response.data.postsOutput;
				if (!postsListOutput) {
					return;
				}
				// Update the whole posts list container
				this.filterList.innerHTML = postsListOutput;

				// Updating all the navigation blocks if exist
				if (!this.navigationBlocks.length) {
					return;
				}

				// Updated the navigation Blocks output
				let navigationBlockOutput = response.data.navigationBlockOutput;
				if (!navigationBlockOutput) {
					return;
				}

				[...this.navigationBlocks].map(block => {
					block.innerHTML = navigationBlockOutput;
				});
			},
			complete: () => {
				this.isProcessingAjax = false;
				// Hide loader
				this._updateLoaderState(false);
				// Scroll to the top of the block
				this._scrollToBlockTop();
			},
			error: xhr => {
				console.log('error:', xhr);
			},
		});
	}

	/**
	 * Show Loader above the main filter block. show = true - show loader; show = false - hide loader
	 *
	 * @param show
	 * @private
	 */
	_updateLoaderState(show = true) {
		if (show) {
			this.container.classList.add('opacity-50', 'pointer-events-none');
		} else {
			this.container.classList.remove(
				'opacity-50',
				'pointer-events-none',
			);
		}
	}

	/**
	 * Pagination functionality
	 *
	 * @param event
	 * @private
	 */
	_navigationHandler(event) {
		event.preventDefault();

		const target = event.target;
		const button = target.closest(`[data-role='navigation-button']`);
		if (!button) {
			return;
		}
		let currentPage = parseInt(this.pageInput.value);
		currentPage = isNaN(currentPage) ? 1 : currentPage;

		const direction = button.getAttribute('data-value');
		switch (direction) {
			case 'prev':
				currentPage--;
				break;
			case 'next':
				currentPage++;
				break;
			default:
		}

		this.pageInput.value = currentPage > 0 ? currentPage : 1;
		this._requestPosts();
	}

	/**
	 * Smooth Scrolling to the top of the block. Better to use in mobile mode
	 * @private
	 */
	_scrollToBlockTop() {
		let bodyOffset = document.body.getBoundingClientRect().top;
		let blockOffset = this.container.getBoundingClientRect().top;

		let neededOffset = blockOffset - bodyOffset - this.blockOffsetTop;
		neededOffset = neededOffset > 0 ? neededOffset : 0;

		window.scrollTo({
			top: neededOffset,
			behavior: 'smooth',
		});
	}

	/**
	 * Opens a filter block on mobile resolution and add overflow:hidden for body
	 *
	 * @private
	 */
	_openFiltersBlock() {
		if (!this.isMobileMode || !this.filtersBlock) {
			return;
		}

		document.body.style.overflow = 'hidden';
		this.filtersBlock.classList = '';
		this.filtersBlock.classList.add(...this.filtersBlockOpenedClasses);
	}

	/**
	 * Closes a filter block on mobile resolution and add overflow:auto for body
	 *
	 * @private
	 */
	_closeFiltersBlock() {
		if (!this.isMobileMode || !this.filtersBlock) {
			return;
		}

		document.body.style.overflow = 'auto';
		this.filtersBlock.classList = '';
		this.filtersBlock.classList.add(...this.filtersBlockClosedClasses);
	}

	/**
	 * Updates Mode Property
	 * @private
	 */
	_updateMode() {
		this.isMobileMode =
			document.body.clientWidth <= this.maxMobileResolution;
	}
}

function filterType1() {
	const filterBlocks = document.querySelectorAll(
		`[data-role='filter-block-1']`,
	);
	if (!filterBlocks.length) {
		return;
	}
	filterBlocks.forEach(block => {
		new FilterTypeOne(block);
	});
}

export default filterType1;
