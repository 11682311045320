import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function latestPostsD1() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	$('[data-module="latestPostsD1"]').each(function() {
		new Swiper($(this)[0], {
			loop: false,
			slidesPerView: 'auto',
		});
	});
}

export default latestPostsD1;
