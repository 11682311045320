import Swiper, {
	Autoplay,
	Controller,
	EffectCube,
	EffectFade,
	Navigation,
	Pagination,
} from 'swiper';

function hero1() {
	Swiper.use([
		Autoplay,
		Navigation,
		Pagination,
		EffectCube,
		EffectFade,
		Controller,
	]);

	$('[data-module="hero1"]').each(function() {
		var swiper_titles = new Swiper(
			$(this).find('.js-hero-1-title-slides')[0],
			{
				loop: true,
				direction: 'vertical',
				effect: 'cube',
				speed: 750,
				allowTouchMove: false,

				cubeEffect: {
					shadow: false,
					slideShadows: false,
				},

				autoplay: {
					delay: 3000,
				},
			},
		);

		var swiper_images = new Swiper(
			$(this).find('.js-hero-1-images-slides')[0],
			{
				loop: true,
				effect: 'fade',
				allowTouchMove: false,
				fadeEffect: { crossFade: true },
			},
		);

		swiper_titles.controller.control = swiper_images;
		swiper_images.controller.control = swiper_titles;

		swiper_titles.on('slideChangeTransitionEnd', function(e) {
			$(e.$el)
				.find('.swiper-slide')
				.removeClass('o-hero-1__title-slides-slide--init');
			$(e.$el)
				.find('.swiper-slide')
				.removeClass('o-hero-1__title-slides-slide--active');

			$(e.$el)
				.find('.swiper-slide')
				.eq(e.realIndex + 1)
				.addClass('o-hero-1__title-slides-slide--active');
		});
	});
}

export default hero1;
